import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"
import LeftMenu from "./leftMenu"

// import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import '@trendmicro/react-sidenav/dist/react-sidenav.css'

const NationalStudentEssayCompetition = () => (
  <Layout>
    <SEO title="National Student Essay Competition" />

    <div className="subpageContainer">

      <LeftMenu active="home" />
      <div className="body">

        <h1 style={{marginTop: '55px'}}>when the message is the medium</h1>
        <h4>National Student Essay Competition: Grades Six through University</h4>
        <p>Students in grades six through eight, in grades nine through twelve, and those at universities and colleges  were invited to submit essays examining the state of freedom of the press in the United States today in light of the First Amendment to our Constitution.</p>
        <p>The response from even the youngest of student essayists has been impressive and inspiring. We received more than 200 thoughtful essays from across the country. We applaud these yet untapped but informed and savvy voices that bode well for our country’s democracy, a free press and America’s future leadership. Finalists will be posted later in June and the winners, announced mid-July.</p>
        <p>We are especially grateful to the editors, publishers and newspapers, large and small, that took precious time during the demands of COVID reporting and, most recently, the civil rights protests to encourage student to submit essays. We send special thanks to Black Voice News, The Boston Globe, The Citizen Chronicle, Examiner Media, The Tennessean, Plymouth Review News, YourArlington.com, and the  TucsonSentinel.com.  We extend our appreciation as well to the Committee to Protect Journalists, the Reporters Committee for Freedom of the Press, the News Leaders Association, and our Honorary Advisory Committee for their invaluable support.</p>
        <p>The generous funding and support of the McCarthey Family Foundation, the inspiring sponsorship of The Boston Globe, and the stunning scholarship from Westminster College are making the voices of our students, on behalf of press freedom, heard loud and clear across the country. Winners will be recognized at the 15th Annual McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism November 7, 2020.</p>
        
        {/* <p>The McCarthey Family Foundation is funding the competition in collaboration with the Boston Globe as media sponsor. The Boston Globe Foundation will provide prize awards to the winning essayists and Westminster College in Salt Lake City will award a full tuition scholarship at the national awards ceremony to be held at the 15th Annual McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism in the fall of 2020.</p>
        <p>Winning essayists in each category will receive $5,000 in prize money. Westminster College, a private liberal arts college in Salt Lake City with professional programs in an environment dedicated to civic engagement, will award a four-year scholarship, current total value of $152,000, to the winning essayist with the highest ranking by the competition jury among the three categories. Winner of the popular vote will receive a check for $1,000 from MKL Public Relations.</p> */}

        

        {/* 
        <p>We are now asking students from around the country to <Link to="/students-ask-newspapers-to-make-their-voices-heard">join us in addressing why a free press matters in our democracy</Link>.</p>
        <p>The competition aims not only to cultivate an informed and enlightened public but also to encourage an understanding of the First Amendment that strengthens freedom of the press and rebuilds trust. We believe there is no more important time than now to focus conversation and critical reflection on understanding the crucial relationships among the First Amendment, a free press, and the foundations of democracy.</p>
        <p>The McCarthey Family Foundation is funding the competition in collaboration with the Boston Globe as media sponsor. The Boston Globe Foundation will provide prize awards to the winning essayists and Westminster College in Salt Lake City will award a full tuition scholarship at the national awards ceremony to be held at the 15th Annual McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism in the fall of 2020. </p>
        <p>Students in grades six through eight, in grades nine through twelve, and those at universities and colleges across the country are invited to submit essays examining the state of freedom of the press in the United States today in light of the First Amendment to the US Constitution.</p>
        <p>Winning essayists in each category will receive $5,000 in prize money. Westminster College, a private liberal arts college with professional programs in an environment dedicated to civic engagement, will award a four-year scholarship, currently valued at $38,000 a year, to the winning essayist with the highest ranking by the competition jury among the three categories. Call-for-entries and scholarships details will be announced in February 2020. <Link to="/essay-timeline">View the competition timeline.</Link></p>
         */}
        <h2>the medium</h2>
        
        <p>The competition aimed not only to cultivate an informed and enlightened public but also to encourage an understanding of the First Amendment that strengthens freedom of the press and rebuilds trust. We believe there is no more important time than now to focus conversation and critical reflection on understanding the crucial relationships among the First Amendment, a free press, and the foundations of democracy.</p>


    </div>
    
        </div>


  </Layout>
)

export default NationalStudentEssayCompetition
